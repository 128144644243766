import { errorsService } from '@/main'
import client from '@/services/client'
import type {
  ILoginForm,
  IRegisterForm,
  ITwoFactorMethods,
  IVerifyMethods,
  IUserAuth,
  IRegenerateToken,
  IReauthResponse,
} from '@/types/UserTypes'
import { isAxiosError } from 'axios'
import { errorsEnum } from '@/types/ErrorTypes'

interface IGen2FAResult {
  qrCode: {
    image: string
  }
  secret: {
    secret: string
  }
}

export interface IOptions {
  ignoreToast: boolean
}

interface IManagePayload {
  option: 'accept_request' | 'email_verification' | 'authenticator_verification'
  code?: string
  user_id?: number
}
class AuthRepository {
  public async login(form: ILoginForm): Promise<IUserAuth> {
    return await client
      .post<IUserAuth>('auths/login', form)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  }

  public async logout(options?: IOptions): Promise<void> {
    return await client
      .post<IUserAuth>('auths/logout')
      .then(() => {
        return
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.Login,
            response: error.response,
            ignoreToast: options?.ignoreToast,
          })
        }

        throw error
      })
  }

  public async reauth(options?: IOptions): Promise<IReauthResponse> {
    return await client
      .get<IReauthResponse>('auths')
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.Reauth,
            response: error.response,
            ignoreToast: options?.ignoreToast,
          })
        }

        throw error
      })
  }

  public async add(form: IRegisterForm): Promise<IRegisterForm> {
    return await client
      .post(`auths/register`, form)
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) return ''
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.AddRegistration,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async sendEmail(): Promise<void> {
    try {
      await client.post<boolean>('auths/2fa/email')
    } catch (error) {
      if (isAxiosError(error)) {
        if (!error.response) throw error
        errorsService.setScopeErrorsFromResponse({
          scope: errorsEnum.SendEmail,
          response: error.response,
        })
      }

      throw error
    }
  }

  public async reSendActiveEmail(email: string): Promise<void> {
    try {
      await client.post<boolean>('auths/email/resend', { email })
    } catch (error) {
      if (isAxiosError(error)) {
        if (!error.response) throw error
        errorsService.setScopeErrorsFromResponse({
          scope: errorsEnum.SendEmail,
          response: error.response,
        })
      }

      throw error
    }
  }

  public async verifyAuth(
    code: string,
    requestIntent: IVerifyMethods
  ): Promise<IUserAuth> {
    try {
      const result = await client.post<IUserAuth>('auths/2fa/verify', {
        code,
        requestIntent,
      })
      return result.data
    } catch (error) {
      if (isAxiosError(error)) {
        if (!error.response) throw error
        errorsService.setScopeErrorsFromResponse({
          scope: errorsEnum.VerifyAuth,
          response: error.response,
          ignoreToast: false,
        })
      }

      throw error
    }
  }

  public async confirmEmail(code: string): Promise<IUserAuth> {
    try {
      const token = await client.post<IUserAuth>('auths/2fa/email', {
        code: code,
      })
      return token.data
    } catch (error) {
      if (isAxiosError(error)) {
        if (!error.response) throw error
        errorsService.setScopeErrorsFromResponse({
          scope: errorsEnum.VerifyAuth,
          response: error.response,
          ignoreToast: true,
        })
      }

      throw error
    }
  }

  // in case we want to confirm an action
  public async confirm2FA(token: string): Promise<IUserAuth> {
    try {
      const result = await client.post<IUserAuth>('auths/2fa/authenticator', {
        token: token,
      })
      return result.data
    } catch (error) {
      if (isAxiosError(error)) {
        if (!error.response) throw error
        errorsService.setScopeErrorsFromResponse({
          scope: errorsEnum.VerifyAuth,
          response: error.response,
          ignoreToast: true,
        })
      }
      throw error
    }
  }

  // in case we are assigning new 2fa
  public async confirmNew2FA(
    token: string,
    secret: string
  ): Promise<IUserAuth> {
    try {
      const result = await client.post<IUserAuth>('auths/2fa/authenticator', {
        token: token,
        secret: secret,
      })
      return result.data
    } catch (error) {
      if (isAxiosError(error)) {
        if (!error.response) throw error
        errorsService.setScopeErrorsFromResponse({
          scope: errorsEnum.VerifyAuth,
          response: error.response,
        })
      }
      throw error
    }
  }

  public async manage2FA(payload: IManagePayload): Promise<boolean> {
    try {
      const result = await client.patch<boolean>(
        'auths/2fa/authenticator',
        payload
      )
      return result.data
    } catch (error) {
      if (isAxiosError(error)) {
        if (!error.response) throw error
        errorsService.setScopeErrorsFromResponse({
          scope: errorsEnum.Manage2FA,
          response: error.response,
        })
      }

      throw error
    }
  }

  public async generate2FACode(): Promise<IGen2FAResult> {
    try {
      const result = await client.get<IGen2FAResult>('auths/2fa/authenticator')
      return result.data
    } catch (error) {
      if (isAxiosError(error)) {
        if (!error.response) throw error
        const message =
          'The reset expiration time has passed. You need to request a new 2FA reset.'
        if (error.response?.data?.checkGenerated[0] === message) {
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.Manage2FA,
            response: error.response,
            ignoreToast: true,
          })
        } else {
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.Manage2FA,
            response: error.response,
          })
        }
      }

      throw error
    }
  }

  public async resetTwoFactor(userId: number): Promise<void> {
    try {
      return await client.post('auths/2fa/reset', { userId })
    } catch (e) {
      if (isAxiosError(e)) {
        if (!e.response) throw e
        errorsService.setScopeErrorsFromResponse({
          scope: errorsEnum.Reset2FA,
          response: e.response,
        })
      }
      throw e
    }
  }
  public async requestResetAuth(): Promise<void> {
    try {
      return await client.post('auths/2fa/reset/email')
    } catch (e) {
      if (isAxiosError(e)) {
        if (!e.response) throw e
        errorsService.setScopeErrorsFromResponse({
          scope: errorsEnum.Reset2FA,
          response: e.response,
        })
      }
      throw e
    }
  }

  public async resendEmailVerification(email: string): Promise<void> {
    return await client
      .post<void>('auths/email/resend', { email })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.ResendEmailVerification,
            response: error.response,
            ignoreToast: true,
          })
        }

        throw error
      })
  }

  public async changeTwoFactorMethod(method: ITwoFactorMethods): Promise<void> {
    return await client
      .patch<void>('auths/2fa/change-method', { method })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.ChangeMethod,
            response: error.response,
            ignoreToast: true,
          })
        }

        throw error
      })
  }

  public async regenerateToken(): Promise<IRegenerateToken> {
    try {
      const result = await client.post<IRegenerateToken>('auths/regenerate')
      return result.data
    } catch (e) {
      if (isAxiosError(e)) {
        if (!e.response) throw e
        errorsService.setScopeErrorsFromResponse({
          scope: errorsEnum.RegenerateToken,
          response: e.response,
        })
      }

      throw e
    }
  }
}

export default new AuthRepository()
