import { Store, Pinia } from 'pinia-class-component'
import type {
  IAuthAuth,
  ILoginForm,
  IRegisterForm,
  IUserRelations,
  IUserCategories,
  IMenuItem,
  IUserAuth,
  ITwoFactorMethods,
  IUserAuthUser,
  IReauthResponse,
  INotificationsSummary,
  IContext,
} from '@/types/UserTypes'
import UserRepository from '@/repositories/UserRepository'
import { AxiosError } from 'axios'
import type { Nullable } from '@/types/Nullable'
import AuthRepository, { type IOptions } from '@/repositories/AuthRepository'
import CaptchaRepository from '@/repositories/CaptchaRepository'
import { LocalStorageKeys } from '@/types/LocalStorageKeys'
import type { IContextGetResponse } from '@/repositories/UsersManagementRepository'
import { environmentsManager } from '@/main'
import type { IUserPermissionModule } from '@/types/PermissionsTypes'
import type { IPermArray } from '@/types/PermissionsModules'
import { getDomain } from '@/helpers/getDomain'
import { getExpirationDate } from '@/helpers/decodeJWT'
import type { IRole } from '@/types/RolesTypes'

interface IUserStore {
  isLoading: boolean
  isError: boolean
  auth: Nullable<IAuthAuth>
  token: string
  users: IUserRelations[]
  user: Nullable<IUserAuthUser>
  userRoles: IRole[]
  categories: IUserCategories[]
  menuItems: Nullable<IMenuItem>
  unreadNotifications: number
  permissions: IUserPermissionModule[]
  usersDatatableDropdown: Nullable<number>
  reauthPending: boolean
  changeContext: boolean
}

type SetupUser = {
  user?: IUserAuthUser
  menuItems?: IMenuItem
  notificationsSummary?: INotificationsSummary
}

@Store
export default class UserService extends Pinia {
  public store: IUserStore = {
    isLoading: false,
    isError: false,
    auth: null,
    token: '',
    users: [],
    user: null,
    userRoles: [],
    categories: [],
    menuItems: null,
    unreadNotifications: 0,
    permissions: [],
    usersDatatableDropdown: null,
    reauthPending: false,
    changeContext: false,
  }

  public get isLoggedIn(): boolean {
    return Boolean(this.store.user)
  }

  public get isLoading(): boolean {
    return this.store.isLoading
  }

  public get isError(): boolean {
    return this.store.isError
  }

  public get hasTwoFactor(): boolean {
    return Boolean(this.store.auth?.twoFactorEnabled)
  }

  public get twoFactorMethod(): Nullable<ITwoFactorMethods> {
    if (!this.store.auth) return null
    return this.store.auth?.twoFactorMethod
  }

  public get getContext(): Nullable<IContext> {
    return this.store.user?.context ?? null
  }

  public get users(): IUserRelations[] {
    return this.store.users
  }

  public get getUser(): Nullable<IUserAuthUser> {
    return this.store.user
  }

  public get menuItems(): IMenuItem {
    return this.store.menuItems ?? {}
  }

  public get isReauthPending(): boolean {
    return this.store.reauthPending
  }

  public get userRoles(): IRole[] {
    return this.store.userRoles
  }
  public setUserRoles(roles: IRole[]): void {
    this.store.userRoles = roles
  }

  public getTokenCookie(): string {
    const token =
      localStorage.getItem(LocalStorageKeys.TOKEN) ||
      localStorage.getItem(LocalStorageKeys.ANONYMOUS_TOKEN)
    return token || ''
  }

  public setTokenLocalStorage(token: string): void {
    localStorage.setItem(LocalStorageKeys.TOKEN, token)
    this.store.token = token
  }

  public removeTokenLocalStorage(): void {
    this.store.token = ''
    localStorage.removeItem(LocalStorageKeys.TOKEN)
  }

  public getToken(): string {
    const token =
      localStorage.getItem(LocalStorageKeys.TOKEN) ||
      localStorage.getItem(LocalStorageKeys.ANONYMOUS_TOKEN)
    if (!token) return ''

    return token
  }

  public clearUserData(): void {
    const domain = getDomain()
    document.cookie.split(';').forEach(function (c) {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(
          /=.*/,
          '=;expires=' + new Date().toUTCString() + `;path=/; domain=.${domain}`
        )
    })

    this.store.token = ''
    localStorage.removeItem(LocalStorageKeys.COUNTER)
    localStorage.removeItem(LocalStorageKeys.TOKEN)
    this.store.auth = null
    this.store.user = null
    this.store.users = []
    this.store.menuItems = null
    this.removeTokenLocalStorage()
    this.removeAnonymousToken()
    this.clearDatatablesFromLocalStorage()
  }

  public get unreadNotifications(): number {
    return this.store.unreadNotifications
  }

  public setSessionTime(): void {
    const date = new Date().getTime()
    document.cookie = `counter=${date}; path=/;`
    localStorage.setItem(LocalStorageKeys.COUNTER, date.toString())
  }

  public setSessionTimeFromToken(): void {
    const token = this.getToken()
    const date = getExpirationDate(token)
    if (!date) return
    localStorage.setItem(LocalStorageKeys.COUNTER, date.getTime().toString())
    document.cookie = `counter=${date.getTime()}; path=/;`
  }

  public setToken(token: string): void {
    const domain = getDomain()
    document.cookie = `token=${token}; path=/; domain=.${domain};`
    localStorage.setItem(LocalStorageKeys.TOKEN, token)
    this.store.token = token
  }

  public setTempToken(token: string): void {
    localStorage.setItem(LocalStorageKeys.ANONYMOUS_TOKEN, token)
    this.store.token = token
  }

  public removeAnonymousToken(): void {
    this.store.token = ''
    localStorage.removeItem(LocalStorageKeys.ANONYMOUS_TOKEN)
  }

  public setAuth(authObject: IAuthAuth): void {
    const token = authObject.token ?? authObject.temp_token
    if (!token) throw new Error('Token is empty')
    if (!authObject.isTemporary) {
      this.setToken(token)
      this.setSessionTime()
      this.clearDatatablesFromLocalStorage()
    }
    this.setTokenLocalStorage(token)
    this.store.auth = authObject
  }

  public setUser(setup: SetupUser): void {
    this.removeAnonymousToken()
    if (setup.user) this.store.user = setup.user
    if (setup.menuItems) this.store.menuItems = setup.menuItems
    this.store.unreadNotifications =
      setup.notificationsSummary?.totalUnreadCount ?? 0
  }

  public setupSession(response: IUserAuth): void {
    this.setAuth(response.authorisation)
    this.setUser({
      user: response.user,
      menuItems: response.menuItems,
      notificationsSummary: response.notificationsSummary,
    })
    this.setSessionTimeFromToken()
  }

  // This method is used to set user context after login
  // when user changes them in the dropdown
  public setContext(context: IContextGetResponse): void {
    if (!this.store.user?.context) return
    this.store.user.context.userId = context.id
    this.store.user.context.roleId = context.role_id
    this.store.user.context.unitId = context.unit_id
  }

  public async verifyCaptcha(
    captchaKey: string,
    userInput: string
  ): Promise<void> {
    await CaptchaRepository.verify(captchaKey, userInput)
      .then((result) => {
        this.setTempToken(result.temporary_token)
      })
      .catch((e) => {
        throw e
      })
  }

  public async getPermissions(): Promise<void> {
    this.store.reauthPending = true
    await UserRepository.getUserPermissions()
      .then((permissions) => {
        this.store.permissions = permissions
      })
      .catch((error) => {
        throw error
      })
      .finally(() => {
        this.store.reauthPending = false
      })
  }

  public async login(form: ILoginForm): Promise<IUserAuth> {
    this.store.reauthPending = true
    const result = await AuthRepository.login(form)
      .then(async (result) => {
        this.setupSession(result)
        return result
      })
      .catch((error) => {
        throw error
      })
      .finally(() => {
        this.store.reauthPending = false
      })

    // Case where 2FA is enabled
    const is2FAEnabled = result.authorisation.twoFactorEnabled
    if (is2FAEnabled) return result

    if (!result.menuItems) {
      await this.reauth()
      return result
    }

    await this.getPermissions()
    return result
  }

  public async loadUsers(): Promise<void> {
    await UserRepository.getUsers()
      .then((response) => {
        this.store.users = response
      })
      .catch((error) => {
        this.store.users = []
        console.error(error)
      })
  }

  public async regenerateToken(): Promise<void> {
    await AuthRepository.regenerateToken().then((response) => {
      this.setToken(response.token)
      this.setSessionTimeFromToken()
    })
  }

  // Reauth doesnt include permissions
  public setupReauth(response: IReauthResponse): void {
    this.setUser({
      user: response.user,
      menuItems: response.menuItems,
      notificationsSummary: response.notificationsSummary,
    })
    this.setSessionTimeFromToken()
  }

  public async reauth(options?: IOptions): Promise<void> {
    this.store.reauthPending = true
    // get permissions
    await AuthRepository.reauth(options)
      .then(async (response) => {
        this.setupReauth(response)
      })
      .catch((error) => {
        this.logout(options)
        this.store.user = null
        console.error(error)
      })
      .finally(() => {
        this.store.reauthPending = false
      })

    if (!this.store.user) return

    this.store.reauthPending = true
    await UserRepository.getUserPermissions()
      .then((permissions) => {
        this.store.permissions = permissions
      })
      .catch((error) => {
        throw error
      })
      .finally(() => {
        this.store.reauthPending = false
      })
  }

  public async logout(options?: IOptions): Promise<void> {
    // https://stackoverflow.com/questions/179355/clearing-all-cookies-with-javascript
    await AuthRepository.logout(options).finally(() => {
      this.clearUserData()
    })
  }

  public async addRegistration(form: IRegisterForm): Promise<void> {
    this.store.isLoading = true
    await AuthRepository.add(form)
      .then(() => {
        this.store.isLoading = false
      })
      .catch((error: Error | AxiosError) => {
        this.store.isLoading = false
        throw error
      })
  }

  public async updateUserData(
    userId: number,
    phone: string,
    lastName: string
  ): Promise<void> {
    this.store.isLoading = true
    await UserRepository.updateData(userId, phone, lastName)
      .then(() => {
        this.store.isLoading = false
      })
      .catch(() => {
        this.store.isError = true
        throw new Error('Error updating user data')
      })
  }

  public clearDatatablesFromLocalStorage(): void {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i)
      if (key && key.startsWith('Datatable')) {
        localStorage.removeItem(key)
        i-- // zmniejszamy indeks, ponieważ długość localStorage uległa zmianie
      }
    }
  }

  public checkAccess(permission: IPermArray): boolean {
    if (environmentsManager.PERMISSIONS_ENABLED !== 'true') return true
    return this.store.permissions.some(
      (perm) =>
        perm.domain_number === permission[0] &&
        perm.group_number === permission[1] &&
        perm.number === permission[2] &&
        perm.pivot.access === 1
    )
  }

  public async restoreNotificationSummary(): Promise<void> {
    await UserRepository.getUserNotifications()
      .then((response) => {
        this.store.unreadNotifications = response?.totalUnreadCount ?? 0
      })
      .catch(() => {
        this.store.unreadNotifications = 0
      })
  }

  public reloadChangeContext(): void {
    this.store.changeContext = !this.store.changeContext
  }
}
