export default {
  common: {
    email: 'Email',
    select: 'Wybierz',
    cancel: 'Anuluj',
    password: 'Hasło',
    repeatPassword: 'Powtórz Hasło',
    dontRememberPassword: 'Nie pamiętasz hasła?',
    resetPassword: 'Zresetuj hasło',
    actualPassword: 'Aktualne hasło',
    newPassword: 'Nowe hasło',
    repeatNewPassword: 'Powtórz nowe hasło',
    login: 'Zaloguj',
    confirm2FA: 'Potwierdź kodem z aplikacji',
    loginWithGoogle: 'Logowanie kontem Google',
    dontHaveAccount: 'Nie masz jeszcze konta?',
    doYouHaveAccount: 'Masz już konto?',
    createAccount: 'Załóż konto',
    or: 'Lub',
    firstName: 'Imię',
    lastName: 'Nazwisko',
    name: 'Nazwa',
    yes: 'Tak',
    no: 'Nie',
    value: 'Wartość',
    phone: 'Telefon',
    headerTime: 'Czas Twojej sesji upłynie za',
    agree: 'Zgadzam się na',
    termsOfUse: 'warunki korzystania z usług',
    enable2fa: 'Włącz dwuetapową weryfikację (TOTP Authenticator)',
    passwordRequiredRules: 'Wymagane',
    chooseCompany: 'Wybierz firmę',
    chooseDept: 'Wybierz dział',
    chooseUnit: 'Wybierz jednostkę',
    chooseRole: 'Wybierz rolę',
    identifier: 'Identyfikator',
    filterByStatus: 'Filtruj po statusie',
    createdAt: 'Data utworzenia',
    endDate: 'Data końcowa',
    position: 'pozycji',
    show: 'Pokaż',
    positionFrom: 'Pozycje od',
    total: 'łącznie',
    to: 'do',
    of: 'z',
    type: 'Typ',
    other: 'Inne',
    all: 'Wszystkie',
    role: 'Rola',
    description: 'Opis',
    notFound: 'Nie znaleziono strony',
    noAccessPage: 'Nie masz dostępu do tej strony',
    attribute: 'Atrybut',
    additionalInfo: 'Dodatkowe informacje',
    company: 'Firma',
    unit: 'Jednostka organizacyjna',
    search: 'Szukaj',
    allow: 'Pozwól',
    deny: 'Zabroń',
    prefix: 'Prefiks',
    hidden: 'Ukryty',
    user: 'Użytkownik',
    expiresAt: 'Wygasa',
    expired: 'Wygasło',
    noResults: 'Brak wyników',
    save: 'Zapisz',
    chooseUser: 'Wybierz użytkownika',
    addToken: 'Dodaj token',
    firstUsedAt: 'Pierwsze użycie',
    lastUsedAt: 'Ostatnie użycie',
    passwordRules: {
      minLength: 'Minimum 8 znaków',
      specialChar: 'Zawiera min 1 znak specjalny',
      number: 'Zawiera min 1 cyfrę',
      upperCase: 'Zawiera min 1 wielką literę',
      lowerCase: 'Zawiera min 1 małą literę',
      baseInfo: 'Podst. zasady bezpieczeństwa dot. haseł',
    },
    dashboard: {
      menu: {
        risk: 'Zarządzanie ryzykiem',
        events: 'Zarządzanie zdarzeniami i incydentami',
        contextCompany: 'Kontekst jednostka',
        contextRole: 'Kontekst rola',
        selectUnit: 'Wybierz jednostkę',
        selectRole: 'Wybierz rolę',
      },
    },
    sessionTimeout: {
      title: 'Zbliża się koniec sesji',
      message: 'Twoja sesja wkrótce wygaśnie i zostaniesz wylogowany/a.',
      stillTimeLeftSession: 'Do końca sesji pozostało',
      ExtendSession: 'Przedłuż sesję aby kontynuować pracę.',
      buttons: {
        logout: 'Wyloguj się',
        extendSession: 'Przedłuż sesję',
      },
    },
    modals: {
      close: 'Zamknij',
      cancel: 'Anuluj',
      confirm: 'Zatwierdź',
      accept: 'Zgadzam się',
      acceptConsent: {
        1: 'Zgadzam się',
        2: 'Zapoznałem się z informacją',
      },
      acceptToProceed:
        'Aby kontynuować, zatwierdź wszystkie zgody i zapoznaj się ze wszystkimi informacjami',
      unsavedChanges:
        'Masz niezapisane zmiany, czy napewno chcesz kontynuować?',
      confirmAction: 'Potwierdź akcję',
    },
    status: 'Status',
    status_id: 'Status',
    none: 'Brak',
    active: 'Aktywny',
    actions: 'Akcje',
    number: 'Numer',
    topic: 'Temat',
    notifications: {
      saveSuccess: 'Zmiany zostały zapisane',
      noRoleSelected: 'Wybierz rolę przed kontynuacją',
      errorCantSave: 'Nie udało się zapisać zmian',
      passwordChanged: 'Hasło zostało zmienione pomyslnie',
      fillAllFields: 'Wypełnij wszystkie wymagane pola',
      phoneUpdated: 'Zmiany zostały zapisane',
    },
    redirect: 'Przekierowuję...',
    logout: 'Zostałeś wylogowany',
    logoutDescription: 'Zaraz zostaniesz przekierowany na stronę logowania',
    asset: 'Aktywo',
    chooseAsset: 'Wybierz aktywo',
    title: 'Tytuł',
    comment: 'Komentarz',
    confirm2FATitle: 'Weryfikacja dwuskładnikowa',
    confirm2FAPara:
      'Dla dodatkowego potwierdzenia twojego dostępu do konta, prosimy o podanie kodu z aplikacji autoryzacyjnej.',
    noRoles: 'Użytkownik nie posiada przypisanych ról w tej jednostce',
    captcha: 'Captcha',
    invalidCaptcha: 'Nieprawidłowa captcha',
    invalidDate: 'Nieprawidłowa data',
    solveCaptcha: 'Rozwiąż zadanie aby kontynuować',
    sessionExpired: 'Sesja wygasła',
    failedToLoad: 'Nie udało się załadować captcha. Spróbuj ponownie.',
    day: 'dzień',
    days: 'dni',
    now: 'Natychmiast',
    lackOfData: 'Brak danych',
    noAccess: 'Brak dostępu',
    noAccessTable: 'Brak uprawnień do wyświetlania tabeli',
    fieldRequired: 'Pole wymagane',
    newError: 'Zapisz poprzednio dodany element aby dodać nowy',
    fillRequiredFields: 'Wypełnij pola',
    loginPage: 'Strona logowania',
    emailRequired: 'Email jest wymagany',
    definition_id: 'Typ',
    print: 'Drukuj',
    sms: 'SMS',
    optionsSelected: 'Wybrano {count} opcje',
    notification: 'Powiadomienie',
    files: {
      dragAndDrop: 'Przeciągnij i upuść plik tutaj',
      clickToSelect: 'lub kliknij aby wybrać pliki',
      selectFiles: 'Wybierz pliki',
      selected: 'Wybrane pliki',
      maxFiles: `Maksymalna liczba plików: {maxFiles}`,
      tooBig: `Plik jest za duży. Maksymalny rozmiar pliku to {maxSize} MB`,
      invalidType: `Nieprawidłowy typ pliku.`,
      noFiles: 'Brak plików',
      noFilesDesc: 'Brak plików do wyświetlenia',
      uploadError: 'Błąd podczas przesyłania pliku',
      deleteFileConfirmation: 'Czy na pewno chcesz usunąć ten plik?',
      fileDeleted: 'Plik został usunięty',
      attachments: 'Załączniki',
      filePreview: 'Podgląd plików',
      download: 'Pobierz',
      attachFiles: 'Dołącz pliki',
      acceptedFileTypes: 'Dozwolone typy plików: ',
      maxFileSize: 'Maksymalny rozmiar pliku: ',
      removeMeta: 'Usuń metadane',
      metaWillBeRemoved: 'Metadane zostaną usunięte z pliku',
      metaWillNotBeRemoved: 'Metadane nie mogą zostac usunięte z tego pliku',
      metaDataRemovalInfo:
        'Metadane mogą być usunięte tylko z plików PDF i obrazów',
      cannotRemoveMeta:
        'Nie można usunąć metadanych z jednego lub więcej plików',
      cannotRemoveMetaFromNewFiles:
        'Nie można usunąć metadanych z jednego lub więcej plików, które zostały dodane',
    },
    newVersion:
      'Dostępna jest nowa wersja aplikacji. Zalecamy odświeżenie strony.',
    discardChanges: 'Odrzuć',
    loadVersion: 'Załaduj nową wersję',
    permissions: {
      blockAccess: 'Zablokuj dostęp',
      inheritAccess: 'Dziedzicz dostęp',
      grantAccess: 'Udziel dostępu',
      blockedByRole: 'Dostęp zablokowany z roli',
      accessByRole: 'Dostęp przyznany z roli',
      unauthorized: 'Brak dostępu',
      unauthRoleDesc: 'Obecna rola nie ma dostępu do tej strony',
      unauthRoleDesc2: 'Zmień rolę poniżej aby uzyskać dostęp, ',
      unauthRoleDescLink: 'lub przejdź do strony głównej',
      unauthRoleDesc3:
        'Jeżeli uważasz, że to błąd, skontaktuj się z administratorem',
    },
    quill: {
      save: 'Zapisz',
      insertLink: 'Link',
      header1: 'Nagłówek 1',
      header2: 'Nagłówek 2',
      header3: 'Nagłówek 3',
      header4: 'Nagłówek 4',
      header5: 'Nagłówek 5',
      header6: 'Nagłówek 6',
      normal: 'Normal',
    },
  },
}
