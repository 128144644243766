<template>
  <div class="tw-ml-4 tw-flex tw-items-center tw-justify-center tw-gap-4">
    <UILoader :is-loading="isLoading">
      <UIListbox
        :calculate-width="true"
        :options="contextCompaniesOptions"
        id="contextCompanySelect"
        :model-value="contextCompanySelect"
        @update:modelValue="handleCompanyChange"
        :placeholder="$t('common.dashboard.menu.selectUnit')"
        :label="$t('common.dashboard.menu.contextCompany')"
      />
    </UILoader>
    <UILoader :is-loading="isLoading || isLoadingRoles">
      <UIListbox
        :calculate-width="true"
        v-if="contextRoleOptions.length > 0"
        :options="contextRoleOptions"
        id="contextRoleSelect"
        :model-value="contextRoleSelect"
        @update:modelValue="handleRoleChange"
        :placeholder="$t('common.dashboard.menu.selectRole')"
        :label="$t('common.dashboard.menu.contextRole')"
      />
      <div v-else class="mx-auto tw-text-center">
        <span>{{ $t('common.noRoles') }}</span>
      </div>
    </UILoader>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import type { SelectOption } from '@/types/CommonTypes'
import { userService } from '@/main'
import UsersManagementRepository, {
  type IUnit,
} from '@/repositories/UsersManagementRepository'
import UILoader from '@/components/UI/UILoader.vue'
import UITransition from '@/components/UI/UITransition.vue'
import UIListbox from '@/components/UI/UIListbox.vue'
import type { Nullable } from '@/types/Nullable'
import type { IContext } from '@/types/UserTypes'

@Component({
  components: {
    UIListbox,
    UITransition,
    UILoader,
  },
  emits: ['contextChange'],
})
export default class HeaderContext extends Vue {
  public userUnits: IUnit[] = []
  public isLoading: Boolean = false
  public isLoadingRoles: Boolean = false

  public get context(): Nullable<IContext> {
    return userService.getContext
  }

  public get contextCompanySelect(): string {
    return String(this.context?.unitId)
  }

  public get contextRoleSelect(): string {
    return String(this.context?.roleId)
  }

  public get contextRoleOptions(): SelectOption[] {
    return userService.userRoles.map((role) => {
      return {
        label: role.name,
        value: String(role.id),
      }
    })
  }

  public async getUserOptions(): Promise<void> {
    this.isLoadingRoles = true
    const user = userService.getUser
    const companyId = this.contextCompanySelect
    const userId = user?.id

    if (!companyId || !userId) return

    await UsersManagementRepository.getUserRoles(userId, Number(companyId))
      .then((response) => {
        userService.setUserRoles(response)
        return response
      })
      .catch(() => {
        return []
      })
      .finally(() => {
        this.isLoadingRoles = false
      })
  }

  public async reauth(): Promise<void> {
    this.isLoading = true
    await userService.reauth().finally(() => {
      this.$emit('contextChange')
      this.isLoading = false
    })
  }

  public async handleCompanyChange(value: string): Promise<void> {
    const payload = { companyContext: Number(value) }
    const userId = userService.getUser?.id
    if (!userId) return
    await UsersManagementRepository.updateUserContext(userId, payload)
      .then(async (response) => {
        try {
          userService.setContext(response)
          await this.getUserOptions()
          await this.reauth()
        } catch (error) {
          console.error(error)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  public async handleRoleChange(value: string): Promise<void> {
    const userId = userService.getUser?.id
    if (!userId) return
    const payload = {
      roleContext: Number(value),
    }
    try {
      const response = await UsersManagementRepository.updateUserContext(
        userId,
        payload
      )
      userService.setContext(response)
      await this.reauth()
    } catch (error) {
      console.error(error)
    }
  }

  public get contextCompaniesOptions(): SelectOption[] {
    const user = userService.getUser
    if (!user) return []

    return (
      user.units?.map((unit) => {
        const { name, id } = unit.company
        return {
          label: `${unit.name} (${name})`,
          value: String(unit.id),
          groupId: String(id),
          groupLabel: name,
        }
      }) ?? []
    )
  }

  public async mounted(): Promise<void> {
    this.isLoading = true
    this.getUserOptions().finally(() => {
      this.isLoading = false
    })
  }
}
</script>
