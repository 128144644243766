export default {
  notifications: {
    title: 'Ustawienia powiadomień',
    all: 'Wszystkie',
    today: 'Dzisiaj',
    other: 'Pozostałe',
    'no-notifications': 'Brak powiadomień',
    deleteAllReadedNotifications: 'Usuń przeczytane',
    markAllAsRead: 'Wszystkie przeczytane',
  },
}
