<template>
  <div
    v-if="showUpdateNotification"
    class="update-notification tw-fixed tw-bottom-[22px] tw-right-[24px] tw-z-[10000] tw-rounded-lg tw-border tw-border-calendarSuccess tw-bg-greend4 tw-p-2.5 tw-shadow-md dark:tw-bg-grey21"
  >
    <p class="tw-p-4 tw-pb-0 tw-font-bold">{{ $t('common.newVersion') }}</p>
    <div class="tw-flex tw-justify-around">
      <UIButton
        @click="rejectUpdate"
        type="secondary"
        class="!tw-bg-white dark:!tw-bg-gray-800"
        :label="$t('common.discardChanges')"
      ></UIButton>
      <UIButton
        @click="reloadPage"
        type="primary"
        :label="$t('common.loadVersion')"
      ></UIButton>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import type { Nullable } from '@/types/Nullable'
import UIButton from '@/components/UI/UIButton.vue'

@Component({
  components: { UIButton },
})
export default class NewVersionNotification extends Vue {
  public showUpdateNotification: boolean = false
  //eslint-disable-next-line
  public existVersion: Nullable<string> = null
  private notificationKey: 'update-notification' = 'update-notification'

  public mounted(): void {
    this.startWatchingManifest()
  }

  private startWatchingManifest(): void {
    this.fetchVersioning()
    const MINUTE = 60 * 1000
    setInterval(async () => this.fetchVersioning(), MINUTE)
  }

  public async fetchVersioning(): Promise<void> {
    const manifestUrl = '/.version'
    let initialCheck = true
    try {
      const myHeaders = new Headers()
      myHeaders.append('pragma', 'no-cache')
      myHeaders.append('cache-control', 'no-cache')

      const myInit = {
        method: 'GET',
        headers: myHeaders,
      }

      await fetch(manifestUrl, myInit).then(async (response) => {
        await response.text().then((newVersion) => {
          const version = newVersion.trim()
          const storedVersion = localStorage.getItem(this.notificationKey)
          if (initialCheck) {
            this.existVersion = version
            initialCheck = false
            return
          }

          if (storedVersion && storedVersion !== version) {
            this.showUpdateNotification = true
          }

          if (!storedVersion || storedVersion !== version) {
            this.existVersion = version
            localStorage.setItem(this.notificationKey, version)
          }
        })
      })
    } catch (error) {
      console.error('Error fetching manifest:', error)
    }
  }

  public rejectUpdate(): void {
    this.showUpdateNotification = false
    if (this.existVersion) {
      localStorage.setItem(this.notificationKey, this.existVersion)
    }
  }

  public reloadPage(): void {
    localStorage.removeItem(this.notificationKey)
    window.location.reload()
  }
}
</script>
