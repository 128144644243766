export default {
  login: {
    anonymousReport: 'Zgłoszenie anonimowe',
    title: 'Logowanie',
    termsOfService: 'Warunki korzystania z usługi',
    error: {
      invalidCredentials: 'Nieprawidłowe dane logowania',
      codeNotEntered: 'Kod nie został wpisany',
      incorrectCode: 'Kod nie jest prawidłowy albo nieważny',
    },
    emailSent: 'Email został wysłany',
    emailVerified: 'Email został zweryfikowany',
    confirmEmail: 'Potwierdź email',
    confirmEmailSent: 'Na twojego emaila zostal wyslany kod potwierdzajacy',
    resetAuthApp:
      'Jeśli nie masz dostepu do swojej aplikacji, złóż wniosek do administratora o reset weryfikacji dwuetapowej',
    resendEmail: 'Wyślij ponownie emaila z potwierdzeniem',
    generateNew2FA: 'Generowanie nowego kodu weryfikacji dwuetapowej',
    generateNewEmailVerification: 'Wyślij email ponownie',
    your2FA: 'Twój kod weryfikacji dwuetapowej',
    resetExpirationTimePassed:
      'Czas na reset weryfikacji dwuetapowej wygasł. Musisz złożyć nowy wniosek.',
    reset2FASent: 'Wniosek o reset weryfikacji dwuetapowej został złożony',
    unActiveAccountMessage:
      'Aktywuj konto poprzez link w emailu, jeśli go nie dostałeś skorzystaj z przycisku ponownego wysłania emaila aktywacyjnego',
    unActiveAccountResend: 'Wyślij ponnownie email aktywacyjny',
    emailAlreadyVerified: 'Email został już zweryfikowany',
    emailConfirmationInvalid:
      'Link aktywacyjny jest nieprawidłowy. Sprawdź swój email lub wyślij ponownie.',
    enterEmailVerificationCode:
      'Na twoj adress email został wysłany kod weryfikacyjny. Wprowadź go poniżej.',
    requestSent: 'Wniosek został wysłany',
  },
}
