<template>
  <div class="c-unitForm tw-w-full">
    <div class="d-flex mt-3">
      <div class="col-12 col-lg-5 p-2 tw-mb-4">
        <UIInput
          v-model="form.name"
          :disabled="disabled"
          id="name"
          name="name"
          @update:modelValue="isChanged = true"
          :label="$t('views.companies.name')"
          :is-error="isError(errors.name)"
          :error="getError(errors.name)"
        />
      </div>
      <div class="col-12 col-lg-2 p-2 tw-mb-4">
        <UIInput
          :model-value="form.weight"
          :disabled="disabled"
          id="weight"
          name="weight"
          type="number"
          :label-on-border="form.weight !== null && Number(form.weight) > -1"
          :is-error="isError(errors.weight)"
          :error="getError(errors.weight)"
          @update:modelValue="handleWeightChange"
          :label="$t('views.companies.unitWeight')"
        />
      </div>
      <div class="">
        <UIButton
          v-if="isChanged"
          :disabled="disabled"
          type="primary"
          :label="$t('button.save')"
          size="medium"
          button-class="!tw-mb-0 tw-mt-2.5 tw-mr-2"
          @click="save()"
        >
          <template #left>
            <font-awesome-icon
              icon="icon fa-solid fa-check"
              :class="{ icon: true }"
              class="tw-mr-2 tw-text-xs"
            />
          </template>
        </UIButton>
        <UIButton
          v-if="unit"
          :disabled="disabled"
          type="primary"
          :label="$t('button.addSubUnit')"
          size="medium"
          button-class="tw-mr-2 !tw-mb-0 tw-mt-2.5"
          @click="addNew()"
        >
          <template #left>
            <font-awesome-icon
              icon="icon fa-solid fa-plus"
              :class="{ icon: true }"
              class="tw-mr-2 tw-text-xs"
            />
          </template>
        </UIButton>
        <UIButton
          type="secondary"
          :disabled="disabled"
          size="medium"
          button-class="!tw-w-auto tw-ml-6 tw-ml-auto !tw-mb-0 tw-mt-2.5 px-3 py-2.5"
          @click="deleteUnit()"
        >
          <template #left>
            <font-awesome-icon
              icon="icon fa-solid fa-trash"
              :class="{ icon: true }"
              class="tw-text-xs"
            />
          </template>
        </UIButton>
      </div>
    </div>
    <template v-if="unit">
      <UnitChild
        :company-id="companyId"
        :parent-id="unit.id"
        :show-empty-form="showEmptyForm"
        :units="childrenItems"
        @deleteNew="showEmptyForm = false"
        @update="$emit('update')"
      />
    </template>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import UIInput from '@/components/UI/UIInput.vue'
import type {
  ICompanyUnit,
  ICompanyUnitErrors,
  ICompanyUnitForm,
} from '@/types/CompaniesTypes'
import UIButton from '@/components/UI/UIButton.vue'
import { companiesService, errorsService } from '@/main'
import UnitChild from '@/components/views/companies/UnitChild.vue'
import CompaniesRepository from '@/repositories/CompaniesRepository'
import { toast } from 'vue3-toastify'
import { errorsEnum, type IError } from '@/types/ErrorTypes'
import { getError, isError } from '@/helpers/errorHelpers'
import type { Nullable } from '@/types/Nullable'

@Component({
  components: {
    UIButton,
    UIInput,
    UnitChild,
  },
  emits: ['update', 'deleteNew'],
})
export default class UnitForm extends Vue {
  @Prop({ required: true })
  public companyId!: number

  @Prop({ required: false })
  public unit!: ICompanyUnit

  @Prop({ required: false })
  public parentId!: number

  @Prop({ required: false })
  public disabled!: boolean

  public isChanged: boolean = false

  public showEmptyForm: boolean = false

  public form: ICompanyUnitForm = {
    name: '',
    weight: 0,
    active: true,
  }

  public errors: ICompanyUnitErrors = {
    name: null,
    weight: null,
    active: null,
  }

  public isError: (val: Nullable<string[]>) => boolean = isError
  public getError: (val: Nullable<string[]>) => string = getError

  public handleWeightChange(value: string): void {
    this.form.weight = Number(value)
    this.isChanged = true
  }

  public get units(): ICompanyUnit[] {
    return companiesService.units
  }

  public get childrenItems(): ICompanyUnit[] {
    if (!this.unit) return []
    const id = this.unit.id
    return this.units.filter((unit) => unit.parent_id === id)
  }

  public addNew(): void {
    this.showEmptyForm = true
  }

  public get hasParent(): boolean {
    return this.parentId !== undefined && this.parentId !== null
  }

  public async reloadData(): Promise<void> {
    companiesService.clearCompany()
    companiesService.clearUnits()

    await Promise.all([
      companiesService.loadCompany(Number(this.companyId)),
      companiesService.loadUnits(Number(this.companyId)),
    ])
  }

  public get backendErrors(): IError {
    if (this.unit) {
      return errorsService.getScopeErrors(errorsEnum.UpdateCompanyUnit)
    }

    if (this.hasParent) {
      return errorsService.getScopeErrors(errorsEnum.AddCompanyUnitWithParent)
    }

    return errorsService.getScopeErrors(errorsEnum.AddCompanyUnit)
  }

  public handleClearErrors(): void {
    if (this.unit) {
      return errorsService.clearScopeErrors(errorsEnum.UpdateCompanyUnit)
    }

    if (this.hasParent) {
      return errorsService.clearScopeErrors(errorsEnum.AddCompanyUnitWithParent)
    }

    return errorsService.clearScopeErrors(errorsEnum.AddCompanyUnit)
  }

  public handleSetError(): void {
    this.errors = {
      name: null,
      active: null,
      weight: null,
    }

    if (this.backendErrors) {
      this.errors = {
        ...this.errors,
        ...this.backendErrors,
      }
    }
  }

  public async save(): Promise<void> {
    this.handleClearErrors()
    if (this.unit) {
      try {
        await CompaniesRepository.updateCompanyUnit(
          this.companyId,
          this.unit.id,
          this.form
        )
        toast(this.$t('common.notifications.saveSuccess'), {
          type: 'success',
          position: 'top-right',
          transition: 'slide',
        })
        this.$emit('update')
        await this.reloadData()
      } catch (e) {
        this.handleSetError()
        console.error(e)
      }
      return
    }

    if (this.hasParent) {
      try {
        await CompaniesRepository.addCompanyUnitWithParent(
          this.companyId,
          this.parentId,
          this.form
        )

        this.showEmptyForm = false
        this.$emit('deleteNew')
        this.$emit('update')
        toast.success(this.$t('notifications.saveSuccess'))
        await this.reloadData()
      } catch (e) {
        this.handleSetError()
        console.error(e)
      }
      return
    }

    try {
      await CompaniesRepository.addCompanyUnit(this.companyId, this.form)
      this.showEmptyForm = false
      toast.success(this.$t('notifications.saveSuccess'))
      this.$emit('deleteNew')
      this.$emit('update')
      await this.reloadData()
    } catch (e) {
      this.handleSetError()
      console.error(e)
    }
  }

  public async deleteUnit(): Promise<void> {
    if (this.unit) {
      await companiesService
        .deleteCompanyUnit(this.companyId, this.unit.id)
        .then(async () => {
          toast.success(this.$t('notifications.unitDeleteSuccess'))
          await companiesService.loadUnits(this.companyId)
        })
    } else {
      this.$emit('deleteNew')
      this.showEmptyForm = false
    }
  }

  // @Watch('form', { deep: true })
  // public handleFormChange(): void {
  //   this.isChanged = true
  // }

  public mounted(): void {
    if (this.unit) {
      this.form = {
        name: this.unit.name,
        weight: this.unit.weight ?? 0,
        active: Boolean(this.unit.active),
      }
      this.isChanged = false
    } else {
      this.isChanged = true
    }
  }
}
</script>
