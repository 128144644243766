export default {
  tasks: {
    tasks: 'Zadania',
    filters: 'Filtry',
    introduction: 'Wprowadzanie',
    toBePerformed: 'Do wykonania',
    executed: 'Wykonano',
    fromUser: 'Od użytkownika',
    toUser: 'Do użytkownika',
    my: 'Moje',
    related: 'Powiązane',
    identification: 'Identyfikacja',
    assets: 'Aktywa',
    riskEstimation: 'Szacowanie ryzyka',
    connections: 'Powiązania',
    administrativeNotifications: 'Zgłoszenia administracyjne',
    newTask: 'Nowe zadanie',
    editTask: 'Edytuj zadanie',
    titleAndDescription: 'Tytuł i opis zadania',
    detailedInfo: 'Informacje szczegółowe',
    title: 'Tytuł zadania',
    description: 'Opis zadania',
    type: 'Typ zadania',
    link: 'Powiązanie',
    dueDate: 'Termin realizacji',
    member: 'Użytkownik',
    status: 'Status',
    status_id: 'Filtruj po statusie',
    typ: 'Typ',
    type_id: 'Filtruj po typie',
    system: 'Systemowe',
    legal: 'Prawne',
    user: 'Użytkownika',
    assignment_type_id: 'Typ przypisania',
    user_id: 'Filtruj po użytkowniku',
    role_id: 'Filtruj po roli',
    created: 'Utworzone',
    assigned: 'Przyjęte',
    closed: 'Zakończone',
    canceled: 'Anulowane',
    addTask: 'Dodaj zadanie',
    assignTo: 'Przypisz do',
    informationAboutTask: 'Informacje o zadaniu',
    author: 'Autor',
    assignedTo: 'Przypisane do',
    createdAt: 'Utworzono',
    updatedAt: 'Zaktualizowano',
    startDate: 'Data rozpoczęcia',
    endDate: 'Data zakończenia',
    comment: 'Komentarz',
    undefined: 'Niezdefiniowany',
    changeStatus: 'Status został zmieniony',
    formError: 'Błąd formularza',
    taskAdded: 'Zadanie zostało dodane',
    forward: 'Przekaż',
    closeComment: 'Komentarz',
    taskType: 'typ zadania',
    role: 'Rola',
    taskForRole: 'Zadanie dla roli',
    assignToInfo:
      'Zadania bez przypisanego użytkownika będą dostępne dla wszystkich użytkowników z wybranej roli.',
    person: 'Użytkownik',
    users: 'Użytkownicy',
    email: 'Email',
    sms: 'SMS',
    notification: 'Powiadomienie',
    daysBefore: 'ile dni przed',
    roles: 'Role',
    addNotification: 'Dodaj powiadomienie',
    bell: 'Dzwoneczek',
    relatedTasks:
      'Wskaż z jakimi innymi elementami systemu to zadanie jest powiązane',
    selectUser: 'Wskaż użytkownika, który ma wykonać to zadanie',
    priority: 'Priorytet',
    notificationSettingAlreadyExists:
      'Powiadomienie z tymi parametrami już istnieje',
    notificationSettingAdded: 'Powiadomienie zostało dodane',
    notificationSettingUpdated: 'Powiadomienie zostało zaktualizowane',
    notificationSettingDeleted: 'Powiadomienie zostało usunięte',
    daysBeforeOptions: {
      rightAway: 'Od razu',
      oneDay: '1 dzień',
      threeDays: '3 dni',
      week: '7 dni',
      twoWeeks: '14 dni',
      month: '30 dni',
    },
  },
}
