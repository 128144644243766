import { Store, Pinia } from 'pinia-class-component'
import type { ReportsDefinition, ReportsStore } from '@/types/ReportsTypes'
import ReportsRepository from '@/repositories/ReportsRepository'

@Store
export default class ReportsService extends Pinia {
  public store: ReportsStore = {
    reportsDefinition: [],
    reportsDefinitionConfig: {
      formats: [],
      columns: [],
    },
  }

  public get reportsDefinition(): ReportsDefinition[] {
    return this.store.reportsDefinition
  }

  public get reportsDefinitionExtensions(): string[] {
    return this.store.reportsDefinitionConfig.formats
  }

  public get reportsDefinitionColumns(): string[] {
    return this.store.reportsDefinitionConfig.columns
  }

  public async loadReportDefinitions(): Promise<void> {
    await ReportsRepository.getReportsDefinitions()
      .then((response) => {
        this.store.reportsDefinition = response
      })
      .catch(() => {
        this.store.reportsDefinition = []
      })
      .finally(() => {})
  }

  public async loadReportDefinitionsConfig(reportType?: string): Promise<void> {
    await ReportsRepository.getReportsConfig(reportType)
      .then((response) => {
        this.store.reportsDefinitionConfig = response
      })
      .catch(() => {
        this.store.reportsDefinitionConfig = {
          formats: [],
          columns: [],
        }
      })
      .finally(() => {})
  }
}
