<template>
  <div class="c-reportsTab tw-mt-8">
    <UIDatatable
      :columns="columns"
      :options="options"
      :dropdowns="dropdowns"
      @select="handleSelect"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Setup, Vue } from 'vue-facing-decorator'
import UIDatatable from '@/components/UI/UIDatatable.vue'
import type { Config, ConfigColumns } from 'datatables.net-bs5'
import { useDatatables } from '@/composables/useDatatables'
import type { Dropdown } from '@/types/DatatableTypes'
import { ETICKET_STATUSES, type IStatusOptions } from '@/types/TicketTypes'
import ResidualRisksTab from '@/components/views/assets/ResidualRisksTab.vue'

@Component({
  components: { ResidualRisksTab, UIDatatable },
})
export default class ReportsTab extends Vue {
  @Prop({ required: true }) assetId!: number
  public get columns(): ConfigColumns[] {
    const classStyle = 'tw-p-2.5 tw-leading-6 !tw-pl-0'
    return [
      {
        data: 'identification.asset_id',
        visible: false,
        title: this.$t('common.asset_id'),
        className: classStyle,
      },
      {
        data: 'formatted_id',
        title: '#',
        className: classStyle,
      },
      {
        data: 'first_name_display',
        title: this.$t('common.firstName'),
        className: classStyle,
      },
      {
        data: 'last_name_display',
        title: this.$t('common.lastName'),
        className: classStyle,
      },
      {
        data: 'user.email',
        title: this.$t('common.email'),
        className: classStyle,
      },
      {
        data: 'status_display',
        title: this.$t('common.status'),
        className: classStyle,
      },
      {
        data: 'created_at_display',
        title: this.$t('common.createdAt'),
        className: classStyle,
        render: function (data): string {
          const date = new Date(data)
          return new Intl.DateTimeFormat('pl-PL', {
            dateStyle: 'short',
          }).format(date)
        },
      },
    ]
  }

  @Setup(() => useDatatables('tickets/list'))
  public datatablesOptions!: ReturnType<typeof useDatatables>

  public options!: Config

  public beforeMount(): void {
    const config: Config = {
      searchCols: [{ search: String(this.assetId) }],
    }
    this.options = { ...this.datatablesOptions.defaultOptions, ...config }
  }

  public get statusOptions(): IStatusOptions[] {
    return [
      { value: '', label: this.$t('views.tickets.filter.status.all') },
      {
        value: ETICKET_STATUSES.NEW,
        label: this.$t('views.tickets.filter.status.new'),
      },
      {
        value: ETICKET_STATUSES.OPEN,
        label: this.$t('views.tickets.filter.status.open'),
      },
      {
        value: ETICKET_STATUSES.ASSIGNED,
        label: this.$t('views.tickets.filter.status.assigned'),
      },
      {
        value: ETICKET_STATUSES.IN_VERIFICATION,
        label: this.$t('views.tickets.filter.status.inVerification'),
      },
      {
        value: ETICKET_STATUSES.VERIFIED,
        label: this.$t('views.tickets.filter.status.verified'),
      },
      {
        value: ETICKET_STATUSES.PROCESSED,
        label: this.$t('views.tickets.filter.status.processed'),
      },
      {
        value: ETICKET_STATUSES.EXPLAINED,
        label: this.$t('views.tickets.filter.status.explanatory'),
      },
      {
        value: ETICKET_STATUSES.FINISHED,
        label: this.$t('views.tickets.filter.status.extended'),
      },
    ]
  }

  public get dropdowns(): Dropdown[] {
    return [
      {
        columns: this.statusOptions,
        label: this.$t('common.status'),
        key: this.$t('common.status'),
      },
    ]
  }

  public handleSelect(data: { id: string }): void {
    this.$router.push('/tickets/' + data.id)
  }
}
</script>
